import { toast } from 'react-toastify';
import {
    notificationsTime,
    errorMessage as defaultErrorMessage,
    successMessage as defaultSuccessMessage
} from '../constants';

export const checkIsAdmin = (groups) => groups.some(group => group.GroupName === 'Administrators');

export const convertComponentNames = (oldComponents) => {
    const componentsConversionMap = {
        'PMChk': 'doiResolutions',
        'TPHrs': 'torturedPhrases',
        'FOCRat': 'feetOfClayDetector',
        'CSStat': 'clearSkiesStatus',
        'RWtch': 'retractionWatch',
        'MLDet': 'unnaturalTextDetector',
        'SSPfd': 'watchlistFakeEmailDomains',
        'SSPan': 'watchlistFakeAffiliationNames',
        'SSPidd': 'watchlistFakeNamesOrEmailAddresses',
        'SSPdd': 'watchlistDisposableEmailDomains',
        'SSPba': 'watchlistBadActors',
        'SSPsa': 'watchlistSuspectArticles',
        'SSPmfs': 'watchlistManuscriptsOfferedForSale',
        'SSMeta': 'watchlistMetadataSuspects'
    };
    const newComponents = {};

    Object.entries(oldComponents).forEach(([oldName, value]) => {
        newComponents[componentsConversionMap[oldName] || oldName] = value;
    });

    return newComponents;
}

export const isNumeric = n => !isNaN(parseFloat(n)) && isFinite(n);

export const isInRange = (num, num1, num2) => Math.min(num1, num2) <= num && Math.max(num1, num2) >= num;

export const showSuccessToast = (successMessage = defaultSuccessMessage, timeOut = false) => {
    return toast(successMessage, {
        type: "success",
        position: "top-right",
        autoClose: timeOut || notificationsTime,
    });
};

export const showErrorToast = (error) => {
    let errorMessage = error || defaultErrorMessage;

    if (error instanceof Object) {
        const responseData = error?.response?.data;
        errorMessage = responseData?.errorMessage?.message || responseData?.errorMessage || error.message || defaultErrorMessage;
    }

    return toast(errorMessage, {
        type: "error",
        position: "top-right",
        autoClose: notificationsTime,
    });
};

export const showInfoToast = (message, timeOut = false) => {
    return toast(message, {
        type: "info",
        position: "top-right",
        draggable: true,
        pauseOnHover: false,
        closeOnClick: true,
        autoClose: timeOut || notificationsTime,
    });
};

export const clearToasts = () => {
    return toast.dismiss();
};

export const filterObj = (obj, filterKey) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => key !== filterKey)
    );
};
