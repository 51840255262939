export const token = `${process.env.REACT_APP_TOKEN}`;
export const apiBaseUrl = `${process.env.REACT_APP_API_BASE_URL}`;
export const webSocketUrl = `${process.env.REACT_APP_WEBSOCKET_URL}`;

export const itemsPerPage = 10;
export const errorMessage = "Error, something went wrong";
export const successMessage = "Action completed successfully";
export const notificationsTime = 3000;

export const regExpPassword =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-#!$'�??%^@&*()_+|~=`{}\[\]'<>?,.\/]).{8,}$/;

export const suspectTypeSelectOptions = [
  { value: 1, label: "Fake e-mail domains" },
  { value: 2, label: "Disposable e-mail domains" },
  { value: 3, label: "Fake affiliation names" },
  { value: 4, label: "Fake names or e-mail addresses" },
  { value: 5, label: "Bad actors" },
  { value: 6, label: "Suspect articles" },
  { value: 7, label: "Manuscripts offered for sale" },
  { value: 8, label: "Metadata suspects" },
  { value: 9, label: "GenAI response" },
];

