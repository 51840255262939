import styled from "styled-components";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBBtn,
  MDBModalDialog,
  MDBModalContent,
  MDBTypography,
} from "mdb-react-ui-kit";
import { MDBSwitch } from "mdb-react-ui-kit";
import { Formik, Form, useField } from "formik";

const MDBSwitchWrapper = styled.div`
  .form-check-input[type="checkbox"]:checked {
    background-color: #54b4d3;
  }
  .form-switch .form-check-input:checked[type="checkbox"]:after {
    background-color: #54b4d3;
  }
`;

const SwitchInput = ({ label, id, className, ...props }) => {
  const createSwitchId = (label) => label.toLowerCase().replace(/\s+/g, '-');

  const [field] = useField({ type: "checkbox", ...props });
  const switchId = id || createSwitchId(label);

  return (
    <MDBSwitchWrapper className={className}>
      <MDBSwitch {...field} id={switchId} label={label} />
    </MDBSwitchWrapper>
  );
};

const FilterSelectorModal = ({
  isOpen,
  setIsOpen,
  filterState,
  isFilterStateReady,
  components,
  onSubmit,
}) => {
  const handleClose = () => setIsOpen(false);

  if (!isFilterStateReady) {
    return null;
  }

  return (
    <MDBModal show={isOpen} setShow={setIsOpen} tabIndex="-1">
      <MDBModalDialog size="md">
        <MDBModalContent>
          <MDBModalHeader>
            Filter
            <MDBBtn className="btn-close" color="none" onClick={handleClose} />
          </MDBModalHeader>
          <MDBModalBody className="mt-2">
            <Formik
              key={JSON.stringify(filterState)}
              initialValues={{
                checked: filterState.filter ? filterState.filter.split(",") : [],
                userFilterSwitch: filterState.userFilter
              }}
              onSubmit={(values) => {
                const newState = {
                  filter: values.checked.length ? values.checked.join(",") : "",
                  userFilter: values.userFilterSwitch
                };
                onSubmit(newState);
                handleClose();
              }}
              onReset={(values, { setValues }) => {
                const resetState = {
                  checked: [],
                  userFilterSwitch: false,
                };
                setValues(resetState);
                const newState = {
                  filter: "",
                  userFilter: false,
                };
                onSubmit(newState);
                handleClose();
              }}
            >
              {({ values, handleReset }) => {
                return (
                  <Form>
                    <div className="mb-4">
                      <SwitchInput
                        label={
                          values.userFilterSwitch
                            ? "Showing only user reports"
                            : "Showing all publisher reports"
                        }
                        name="userFilterSwitch"
                        className="mb-3"
                      />
                      {(components.doiResolutions || components.retractionWatch) && (
                        <MDBTypography tag="strong">
                          Reference analysis
                        </MDBTypography>
                      )}
                      {components.doiResolutions && (
                        <SwitchInput
                          name="checked"
                          value="da"
                          label="DOI analysis"
                        />
                      )}
                      {components.retractionWatch && (
                        <SwitchInput
                          name="checked"
                          value="rw"
                          label="Retraction watch"
                        />
                      )}
                      {components.feetOfClayDetector && (
                        <>
                          <MDBTypography tag="strong">PubPeer</MDBTypography>
                          <SwitchInput
                            name="checked"
                            value="pm"
                            label="PubPeer hits"
                          />
                        </>
                      )}
                      {components.clearSkiesStatus && (
                        <>
                          <MDBTypography tag="strong">
                            ClearSkies Papermill Alarm: Public
                          </MDBTypography>
                          <SwitchInput
                            name="checked"
                            value="ps"
                            label="Papermill similarity"
                          />
                        </>
                      )}
                      {components.cactusTool && (
                        <>
                          <MDBTypography tag="strong">
                            Cactus Integrity Checks
                          </MDBTypography>
                          <SwitchInput
                            name="checked"
                            value="ct"
                            label="Cactus Integrity Checks"
                          />
                        </>
                      )}
                      {(components.torturedPhrases ||
                        components.unnaturalTextDetector ||
                        components.genAIResponse ||
                        components.wordFileProperties) && (
                          <MDBTypography tag="strong">
                            Text analysis
                          </MDBTypography>
                        )}
                      {components.torturedPhrases && (
                        <SwitchInput
                          name="checked"
                          value="tph"
                          label="Tortured phrases"
                        />
                      )}
                      {components.genAIResponse && (
                        <SwitchInput
                          name="checked"
                          value="gr"
                          label="GenAI response"
                        />
                      )}
                      {components.unnaturalTextDetector && (
                        <SwitchInput
                          name="checked"
                          value="ut"
                          label="Unnatural text"
                        />
                      )}
                      {components.wordFileProperties && (
                        <SwitchInput
                          name="checked"
                          value="wfp"
                          label="Word file metadata"
                        />
                      )}
                      {Object.keys(components)
                        .some(key => key.startsWith('watchlist')) && (
                          <MDBTypography tag="strong">Watchlist</MDBTypography>
                        )}
                      {components.watchlistFakeAffiliationNames && (
                        <SwitchInput
                          name="checked"
                          value="aff"
                          label="Fake affiliation names"
                        />
                      )}
                      {components.watchlistFakeNamesOrEmailAddresses && (
                        <SwitchInput
                          name="checked"
                          value="idd"
                          label="Fake names or e-mail addresses"
                        />
                      )}
                      {components.watchlistFakeEmailDomains && (
                        <SwitchInput
                          name="checked"
                          value="em"
                          label="Fake e-mail domains"
                        />
                      )}
                      {components.watchlistDisposableEmailDomains && (
                        <SwitchInput
                          name="checked"
                          value="ded"
                          label="Disposable e-mail domains"
                        />
                      )}
                      {components.watchlistBadActors && (
                        <SwitchInput
                          name="checked"
                          value="ba"
                          label="Bad actors"
                        />
                      )}
                      {components.watchlistSuspectArticles && (
                        <SwitchInput
                          name="checked"
                          value="sa"
                          label="Suspect articles"
                        />
                      )}
                      {components.watchlistManuscriptsOfferedForSale && (
                        <SwitchInput
                          name="checked"
                          value="mfs"
                          label="Manuscripts offered for sale"
                        />
                      )}
                      {components.watchlistMetadataSuspects && (
                        <SwitchInput
                          name="checked"
                          value="ms"
                          label="Metadata suspects"
                        />
                      )}
                    </div>
                    <MDBModalFooter className="mt-3 ">
                      <MDBBtn size="md" type="submit">
                        Set
                      </MDBBtn>
                      <MDBBtn
                        size="md"
                        color="danger"
                        onClick={handleReset}
                      >
                        Reset
                      </MDBBtn>
                    </MDBModalFooter>
                  </Form>
                );
              }}
            </Formik>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
};

export default FilterSelectorModal;
